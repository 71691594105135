<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Device Filter -->
            <div class="col-lg col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Filter devices by..."
                v-model="filterBy"
                :options="[
                  { value: 'linked', label: 'Linked' },
                  { value: 'unlinked', label: 'Not Linked' },
                  { value: 'software', label: 'Software' },
                  { value: 'hardware', label: 'Hardware' },
                ]"
                :reduce="o => o.value"
                multiple
                :searchable="false"
                @input="filterDevices"
              />
            </div>

            <!-- Restaurant Filter -->
            <div class="col-lg col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Select restaurant.."
                v-model="selectedRestaurant"
                :options="allRestaurants"
                @input="inputFilter"
                :disabled="restaurantId"
              />
            </div>

            <!-- Search -->
            <div class="col-lg col-filter mb-2 mb-lg-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search.."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No devices found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >

      <!-- Restaurant -->
      <template #restaurant="{item}">
        <td v-if="item.restaurants.length > 0">
          <CLink :to="{name: 'View Restaurant',params: { id: item.restaurants[0].id }}" target="_blank">
            <strong>#{{ item.restaurants[0].id }}</strong>
            {{ item.restaurants[0].restaurant_postcode }}
            {{ item.restaurants[0].restaurant_city }},
            <strong>{{ item.restaurants[0].restaurant_name }}</strong>
          </CLink>
        </td>
        <td v-else class="text-muted">
          Not Linked!
        </td>
      </template>

      <!-- Software -->
      <template #software="{item}">
        <td class="text-muted">
          {{ item.software == true ? 'Software' : 'Hardware'}}
        </td>
      </template>

      <!-- WS Status -->
      <template #ws_status="{item}">
        <td class="py-2 text-center">
          <div v-if="item.ws_status == 1" name="cis-circle" class="circle bg-success"></div>
          <div v-else name="cis-circle" class="circle bg-danger"></div>
        </td>
      </template>

      <!-- Show History -->
      <template #show_history="{item, index}">
        <td class="py-2 text-center">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="toggleHistory(item, index)"
          >
            {{Boolean(item._toggled) ? 'Hide' : 'Show'}}
          </CButton>
        </td>
      </template>

      <!-- History -->
      <template #details="{item}">
        <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
          <CCardBody style="background-color: #fff">
            <div class="table-responsive" v-if="item.history.length">
              <table class="table table-sm table-history">
                <thead>
                  <tr>
                    <th style="width: 5%">Status</th>
                    <th style="width: 15%">Updated At</th>
                    <th style="width: 25%">Linked Restaurant</th>
                    <th style="width: 15%">By</th>
                    <th style="width: 40%">Note</th>
                  </tr>
                </thead>

                <tr v-for="h in item.history" :key="h.id" :class="h.remove ? 'text-danger' : 'text-success'">
                  <th scope="row"><CIcon :name="`cil-${ h.remove ? 'x' : 'check-alt'}`" /></th>
                  <td>{{ h.updated_at }}</td>
                  <td>
                    <CLink v-if="h.restaurant" :to="{name: 'View Restaurant', params: { id: h.restaurant.id }}" target="_blank">
                      #{{ h.restaurant.id }}
                      {{ h.restaurant.restaurant_postcode }}
                      {{ h.restaurant.restaurant_city }},
                      {{ h.restaurant.restaurant_name }}
                    </CLink>
                  </td>
                  <td>
                    <CLink v-if="h.user" :to="{name: 'User', params: { id: h.user.id }}" target="_blank">
                      <strong>{{ h.user.first_name }}</strong>
                    </CLink>
                  </td>
                  <td>{{ h.note }}</td>
                </tr>
              </table>
            </div>
            <span v-else class="text-danger">⚠ No device history found!</span>
          </CCardBody>
        </CCollapse>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="py-2 text-center">
          <CButton :to="{ name: 'Edit Device', params: { id: item.id }}"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-align-left" />
          </CButton>

          <CButton class="ml-1"
            size="sm"
            color="danger"
            variant="ghost"
            shape="pill"
            :disabled="item.restaurants.length > 0"
            @click="deleteItem(item, index)"
          >
            <CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </CCardBody>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  components: {
    vSelect,
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions",                   label: "Actions",             filter: false, sorter: false, _style: "min-width: 100px; text-align: center;" },
        { key: "id",                        label: "#ID",                 filter: false },
        { key: "restaurant",                label: "Restaurant",          filter: false, _style: "min-width: 320px;" },

        { key: "sid",                       label: "String #ID",          filter: false, _style: "min-width: 200px;" },
        { key: "serienr",                   label: "Serial Number",       filter: false, _style: "min-width: 200px;" },
        { key: "version",                   label: "App Version",         filter: false, _style: "min-width: 150px;" },
        { key: "os",                        label: "OS",                  filter: false, _style: "min-width: 150px;" },
        { key: "model",                     label: "Model",               filter: false, _style: "min-width: 200px;" },
        { key: "software",                  label: "Type",                filter: false, _style: "min-width: 100px;" },
        { key: "connectivity",              label: "Connectivity",        filter: false, _style: "min-width: 100px;" },
        { key: "ws_status",                 label: "WS Connection",       filter: false, _style: "min-width: 100px; text-align: center;" },
        { key: "ws_lastcheck_on",           label: "WS LC-ON",            filter: false, _style: "min-width: 100px; text-align: center;" },
        { key: "ws_lastcheck_off",          label: "WS LC-OFF",           filter: false, _style: "min-width: 100px; text-align: center;" },
        { key: "show_history",              label: "History",             filter: false, _style: "min-width: 100px; text-align: center;" },

      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,
      collapseDuration: 0,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Filter işlemleri için
      filterBy: "unlinked", // Device Filter
      allRestaurants: [],
      selectedRestaurant: null,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;

    await Promise.all([
      this.getRestaurants(),
    ]);

    await this.getAllItems();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: this.currentRouteName, query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    currentRouteName () { return this.$route.name },
    restaurantId() { return this.$route.params.restaurant_id },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.DEVICE.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          filter: this.filterBy,
          'restaurant-id': this.selectedRestaurant?.value,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = item.restaurants.length <= 0 ? ' table-success font-italic' : '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getRestaurants() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;

          if(this.restaurantId) {
            this.selectedRestaurant = this.allRestaurants.find(item => item.value == this.restaurantId);
          }
        })
        .catch(() => { this.allRestaurants = [] })
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },

    toggleHistory(item) {
      this.$set(item, '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },

    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.DEVICE.DELETE.replace("{id}", item.id)
              )
              .then((response) => {
                this.$delete(this.loadedItems, index);

                this.showErrorAlert = false
                this.successAlertMessage = {itemName: 'Device', message: 'deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
                this.total--
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    // Filters
    filterDevices(value) {
      this.filterBy = value;
      this.getAllItems();
    },
    inputFilter() {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.search = "";
        this.filterBy = "unlinked";
        !this.restaurantId && (this.selectedRestaurant = null);
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table-history tr:nth-of-type(odd) {
  background-color: #fff !important;
}

.table-history tr:hover {
  background-color: #fff !important;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
</style>
